import React from "react"
import './ErrorPage.scss'
import { Link } from "gatsby"

import {
    Button
} from 'reactstrap'
const ErrorPage = () => (
<div className="error_wrapper text-center">
    <div className="error_container">
        <div className="circle">
            <div className="circle-text">
                <h1>404</h1>
            </div>
            <div className="circle-item"></div>
            <div className="circle-item"></div>
            <div className="circle-item"></div>
        </div>
    </div>
    <h2 className="pb-4">Sorry, page not found</h2>
    <Link to="/"><Button color="primary" size="lg">Get going with brillea</Button></Link>
</div>
)

export default ErrorPage
