import React from "react"
import SEO from "../components/SEO"

import ErrorPage from "../components/ErrorPage/ErrorPage"

class NotFoundPage extends React.Component {
  render() {
    return (
      <>
        <SEO title="404: Not found"/>
        <ErrorPage />
      </>
    )
  }
}

export default NotFoundPage